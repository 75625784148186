@media screen and (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media screen and (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media screen and (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}
@media (max-width:480px) {
    .s1 {
        margin: -127px 0 0 0;
        width: 80%;
        height: 255px;
        left: 10%;
    }
    .s1 p {
        font-size: 36px;
    }
    .s1 span {
        font-size: 18px;
    }
}


@media only screen and (min-width: 768px) {
  .cd-top {
    right: 20px;
    bottom: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .cd-top {
    height: 60px;
    width: 60px;
    right: 30px;
    bottom: 30px;
  }
}