/****************************** components ******************************/

@import "./components/mixin";
@import "./components/default";
@import "./components/normalize";
@import "./components/reset";
@import "./components/grid";
@import "./components/case";

/****************************** style ******************************/

body,
button,
input,
select,
textarea {
    font: 12px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #5a5a5a;
    font-size: 12px;
}

ol li,
ul li {
    list-style-type: none;
    vertical-align: middle;
}

img {
    border: none;
    vertical-align: middle;
}

input {
    vertical-align: middle;
    font: 12px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    outline: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    text-decoration: none;
    outline:none;blr:expression(this.onFocus=this.blur());
    transition: all .5s;
    -moz-transition: all .5s; 
    -webkit-transition: all .5s; 
    -o-transition: all .5s;
}

.clearfix {
    *zoom: 1;
}

.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.content_box,
.content_box *,
.content_box :after,
.content_box :before {
    box-sizing: content-box
}

.clear {
    clear: both;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

body #wapnav_mask {
    width: 100%;
    background: rgba(0,0,0,0);
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 998;
    display: none;
}

.wap_nav.in {
    display: block;
}

.wap_nav.in {
    left: 0;
}

.wap_nav {
    position: fixed;
    width: 4rem;
    height: 100%;
    left: -4rem;
    top: 0;
    z-index: 9999;
    background-color: #fff;
    transition: left .3s;
}

.wap_nav .close {
    width: 100%;
    height: 53px;
    line-height: 53px;
    padding-left: .6rem;
    display: block;
}

.wap_nav .close img {
    width: .32rem;
    height: .32rem;
    vertical-align: middle;
}

.wap_nav > ul {
    height: calc(100% - 53px);
    overflow-x: hidden;
}

.wap_nav li {
    line-height: .95rem;
}

.wap_nav > ul > li > a {
    color: #4c4c4c;
    font-size: .35rem;
}

.wap_nav li a {
    display: block;
    padding-left: .6rem;
    padding-right: .3rem;
}

.wap_nav_btn {
    position: absolute;
    left: .38rem;
    top: 0;
    height: 53px;
    line-height: 53px;
    z-index: 999;
    padding: 0 .2rem;
}

.wap_nav_btn img {
    width: .52rem;
    height: .33rem;
    vertical-align: middle;
}

@media screen and (min-width: 768px) { 
    body {
        background: #fafafa;
    }
    .wap {
        display: none;
    }
    .w1200 {
        width: 1200px;
        margin: 0 auto;
        position: relative;
    }
    .header_b {
        width: 100%;
        height: 100px;
        padding: 25px 0;
    }
    .main {
        padding: 0 0 10px 0;
        .w1200 {
            .main_ll {
                width: 190px;
                margin-right: 15px;
                background-color: #fff;
                position: fixed;
                .header_bl {
                    width: 100%;
                    background-color: #ff5e52;
                }
                .nav {
                    width: 100%;
                    background-color: #fff;
                    ul {
                        width: 100%;
                        li {
                            width: 100%;
                            a {
                                display: block;
                                width: 100%;
                                height: 44px;
                                line-height: 44px;
                                padding-left: 30px;
                                font-size: 16px;
                                color: #666;
                                &:hover {
                                    color: #444;
                                    background-color: #f6f6f6;
                                }
                            }
                            a.active {
                                color: #ff5e52;
                            }
                        }
                    }
                }
                .header_br {
                    width: 100%;
                    padding: 15px;
                    background-color: #f6f6f6;
                    border: 1px solid #eee;
                    position: relative;
                    input[type="text"] {
                        width: 100%;
                        height: 34px;
                        line-height: 34px;
                        border: 1px solid #ff5e52;
                        text-indent: 5px;
                    }
                    input[type="submit"] {
                        width: 18%;
                        height: 34px;
                        line-height: 34px;
                        position: absolute;
                        right: 15px;
                        top: 15px;
                        border: 1px solid #ff5e52;
                        background: #ff5e52;
                    }
                    i {
                        position: absolute;
                        color: #fff;
                        font-size: 20px;
                        right: 22px;
                        top: 22px;
                    }
                }
            }
            .main_l {
                width: 680px;
                margin-left: 205px;
                .banner {
                    .swiper-slide {
                        img {
                            width: 680px;
                        }
                    }
                }
                .iart_t {
                    margin-top: 15px;
                    padding: 0 15px 0;
                    width: 100%;
                    height: 50px;
                    border-top: none;
                    border-bottom: 1px solid #EEE;
                    background: #fff;
                    h2 {
                       float: left;
                        padding-bottom: 12px;
                        height: 50px;
                        line-height: 50px;
                        border-bottom: 2px solid #FF5E52;
                        color: #444;
                        font-size: 16px;
                        font-weight: normal; 
                    }
                }
                .iart_list {
                    ul {
                        background: #fff;
                        padding: 0 15px;
                        li {
                            width: 100%;
                            position: relative;
                            border-bottom: 1px dashed #eee;
                            padding: 25px 0;
                            div.fl {
                                position: relative;
                                float: left;
                                width: 190px;
                                height: 125px;
                                overflow: hidden;
                                a {
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    position: relative;
                                    &:hover {
                                        img {
                                            transform: scale(1.2);
                                            -moz-transform: scale(1.2);
                                            -webkit-transform: scale(1.2);
                                            -o-transform: scale(1.2);
                                            -ms-transform: scale(1.2);
                                        }
                                    }
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        transition: all .5s;
                                        -moz-transition: all .5s; 
                                        -webkit-transition: all .5s; 
                                        -o-transition: all .5s;
                                    }
                                    span {
                                        display: block;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        text-align: center;
                                        height: 30px;
                                        line-height: 24px;
                                        font-size: 12px;
                                        color: #fff;
                                        padding: 3px 8px;
                                    }
                                }
                            }
                            div.fr {
                                width: 445px;
                                a {
                                    line-height: 40px;
                                    font-size: 18px;
                                    color: #31424e;
                                    font-weight: bold;
                                    &:hover {
                                        color: #ff6f3d;
                                    }
                                }
                                .p1 {
                                    font-size: 14px;
                                    color: #5e6b73;
                                    line-height: 22px;
                                    padding: 10px 0;
                                }
                                .p2 {
                                    height: 28px;
                                    line-height: 28px;
                                    font-size: 13px;
                                    color: #9baab6;
                                }
                            }
                        }
                    }
                }
                .iart_list2 {
                    width: 100%;
                    background: #fff;
                    padding: 15px;
                    ul {
                        li {
                            float: left;
                            width: 23%;
                            margin-right: 2.6%;
                            .img {
                                display: block;
                                width: 100%;
                                height: auto;
                                img {
                                    width: 100%;
                                    height: auto;
                                }
                            }
                            .t {
                                display: block;
                                font-size: 14px;
                                color: #666;
                                line-height: 24px;
                                &:hover {
                                    color: #ff5e52;
                                }
                            }
                        }
                        li:nth-child(4n) {
                            margin-right: 0;
                        }
                    }
                }
                .load_more_btn {
                    display: block;
                    background: #f07859;
                    width: 220px;
                    height: 40px;
                    line-height: 40px;
                    margin: 20px auto 10px;
                    font-size: 14px;
                    color: #fff;
                    border-radius: 20px;
                    text-align: center;
                    &:hover {
                        opacity:0.7;filter:alpha(opacity=70);
                    }
                    em {
                        display: inline-block;
                        vertical-align: middle;
                        background: url(../img/biaoqi_moreicon.png) no-repeat -1px -1px;
                        width: 26px;
                        height: 22px;
                    }
                }
            }
            .main_r {
                width: 300px;
                .iart_catlist {
                    background: #fff;
                    box-shadow: 0 0 3px rgba(0,0,0,.1);
                    .iart_catlist_t {
                        padding-top: 14px;
                        span {
                            height: 34px;
                            line-height: 34px;
                            padding: 0 15px 0 12px;
                            background: #ff6f3d;
                            font-size: 15px;
                            font-weight: bold;
                            color: #fff;
                            display: inline-block;
                            border-bottom-right-radius: 17px;
                            border-top-right-radius: 17px;
                            -webkit-border-bottom-right-radius: 17px;
                            -webkit-border-top-right-radius: 17px;
                        }
                    }
                    .iart_catlist_c {
                        padding: 20px 10px;
                        ul {
                            li {
                                float: left;
                                width: 85px;
                                margin-right: 7px;
                                a {
                                    display: block;
                                    padding: 5px 10px;
                                    background: #ff6f3d;
                                    color: #fff;
                                    font-size: 14px;
                                    text-align: center;
                                    margin-bottom: 10px;
                                }
                            }
                            li:nth-child(3n) {
                                margin-right: 0;
                            }
                        }
                    }
                }
                .iart_list2 {
                    box-shadow: 0 0 3px rgba(0,0,0,.1);
                    padding: 15px 15px 0 15px;
                    background: #fff;
                    margin-top: 20px;
                    .iart_list2_t {
                        border-bottom: 1px solid #f3f3f3;
                        span {
                            display: inline-block;
                            padding-bottom: 10px;
                            line-height: 23px;
                            font-size: 16px;
                            font-weight: bold;
                            color: #31424e;
                            border-bottom: 2px solid #ff6f3d;
                        }
                    }
                    .iart_list2_c {
                        ul {
                            li {
                                padding: 15px 0;
                                border-bottom: 1px solid #eee;
                                div.fl {
                                    width: 160px;
                                    a {
                                        line-height: 21px;
                                        font-size: 14px;
                                        font-weight: 400;
                                        display: block;
                                        margin-bottom: 10px;
                                        color: #333;
                                        &:hover {
                                            color: #ff6f3d;
                                        }
                                    }
                                    p {
                                        color: #999;
                                    }
                                }
                                div.fr {
                                    width: 95px;
                                    height: 75px;
                                    a {
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                        img {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                            li:last-child {
                                border: none;
                            }
                        }
                    }
                }
                .ilinks {
                    box-shadow: 0 0 3px rgba(0,0,0,.1);
                    border-top: 2px solid #ff6f3d;
                    background-color: #fff;
                    margin-top: 20px;
                    padding: 15px 15px 20px;
                    .ilinks_t {
                        color: #222;
                        padding: 0;
                        margin-bottom: 15px;
                        font-size: 18px;
                        font-weight: 700;
                    }
                    .ilinks_c {
                        a {
                            float: left;
                            margin: 0 20px 20px 0;
                            color: #777;
                            line-height: 1.4;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    .footer {
        width: 100%;
        background: #333;
        border-top: 14px solid #f07859;
        .footer_t {
            padding: 40px 0;
            border-bottom: 1px solid #515151;
            .footer_tl {
                border-right: 1px solid #515151;
                .footer_list {
                    margin-right: 60px;
                    h5 {
                        font-size: 18px;
                        color: #fff;
                        margin-bottom: 20px;
                        font-weight: normal;
                    }
                    a {
                        display: block;
                        line-height: 30px;
                        font-size: 14px;
                        color: #aeaeae;
                    }
                }  
            }
            .footer_tm {
                padding-left: 65px;
                .p1 {
                    font-size: 18px;
                    color: #fff;
                    margin-bottom: 20px;
                }
                .p2 {
                    line-height: 30px;
                    font-size: 14px;
                    color: #aeaeae;
                    padding-left: 20px;
                }
            }
            .footer_tr {
                img {
                    width: 108px;
                }
                p {
                    text-align: center;
                    color: #fff;
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
        .footer_b {
            color: #bababa;
            padding: 15px 0;
        }
    }
    .nmain {
        .w1200 {
            .main_ll {
                width: 190px;
                margin-right: 15px;
                background-color: #fff;
                position: fixed;
                .header_bl {
                    width: 100%;
                    background-color: #ff5e52;
                }
                .nav {
                    width: 100%;
                    background-color: #fff;
                    ul {
                        width: 100%;
                        li {
                            width: 100%;
                            a {
                                display: block;
                                width: 100%;
                                height: 44px;
                                line-height: 44px;
                                padding-left: 30px;
                                font-size: 16px;
                                color: #666;
                                &:hover {
                                    color: #444;
                                    background-color: #f6f6f6;
                                }
                            }
                            a.active {
                                color: #ff5e52;
                            }
                        }
                    }
                }
                .header_br {
                    width: 100%;
                    padding: 15px;
                    background-color: #f6f6f6;
                    border: 1px solid #eee;
                    position: relative;
                    input[type="text"] {
                        width: 100%;
                        height: 34px;
                        line-height: 34px;
                        border: 1px solid #ff5e52;
                        text-indent: 5px;
                    }
                    input[type="submit"] {
                        width: 18%;
                        height: 34px;
                        line-height: 34px;
                        position: absolute;
                        right: 15px;
                        top: 15px;
                        border: 1px solid #ff5e52;
                        background: #ff5e52;
                    }
                    i {
                        position: absolute;
                        color: #fff;
                        font-size: 20px;
                        right: 22px;
                        top: 22px;
                    }
                }
            }
            .nmain_l {
                width: 680px;
                margin-left: 205px;
                .crumbs {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    font-size: 14px;
                    a {
                        display: inline-block;
                        font-size: 14px;
                        height: 50px;
                    }
                    a:first-child {
                        display: inline-block;
                        float: left;
                        width: 18px;
                        background: url(../img/chart.png) no-repeat 0 -20px;
                    }
                }
                .artlist {
                    ul {
                        background: #fff;
                        box-shadow: 0 0 3px rgba(0,0,0,.1);
                        padding: 0 15px;
                        li {
                            width: 100%;
                            position: relative;
                            border-bottom: 1px dotted #e2e2e2;
                            padding: 20px 0;
                            div.fl {
                                position: relative;
                                float: left;
                                width: 190px;
                                height: 125px;
                                overflow: hidden;
                                a {
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    position: relative;
                                    &:hover {
                                        img {
                                            transform: scale(1.2);
                                            -moz-transform: scale(1.2);
                                            -webkit-transform: scale(1.2);
                                            -o-transform: scale(1.2);
                                            -ms-transform: scale(1.2);
                                        }
                                    }
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        transition: all .5s;
                                        -moz-transition: all .5s; 
                                        -webkit-transition: all .5s; 
                                        -o-transition: all .5s;
                                    }
                                    span {
                                        display: block;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        text-align: center;
                                        height: 30px;
                                        line-height: 24px;
                                        font-size: 12px;
                                        color: #fff;
                                        padding: 3px 8px;
                                    }
                                }
                            }
                            div.fr {
                                width: 445px;
                                a {
                                    line-height: 40px;
                                    font-size: 18px;
                                    color: #31424e;
                                    font-weight: bold;
                                    &:hover {
                                        color: #ff6f3d;
                                    }
                                }
                                .p1 {
                                    font-size: 14px;
                                    color: #5e6b73;
                                    line-height: 22px;
                                    padding: 10px 0;
                                }
                                .p2 {
                                    height: 28px;
                                    line-height: 28px;
                                    font-size: 13px;
                                    color: #9baab6;
                                }
                            }
                        }
                        li:nth-child(4n) {
                            div.fl {
                                a {
                                    span {
                                        background-color: #29bbc0;
                                    }
                                }
                            }
                        }
                        li:nth-child(4n+1) {
                            div.fl {
                                a {
                                    span {
                                        background-color: #ee5757;
                                    }
                                }
                            }
                        }
                        li:nth-child(4n+2) {
                            div.fl {
                                a {
                                    span {
                                        background-color: #34415d;
                                    }
                                }
                            }
                        }
                        li:nth-child(4n+3) {
                            div.fl {
                                a {
                                    span {
                                        background-color: #b145b8;
                                    }
                                }
                            }
                        }
                    }
                }
                .load_more_btn {
                    display: block;
                    background: #f07859;
                    width: 220px;
                    height: 40px;
                    line-height: 40px;
                    margin: 20px auto;
                    font-size: 14px;
                    color: #fff;
                    border-radius: 20px;
                    text-align: center;
                    &:hover {
                        opacity:0.7;filter:alpha(opacity=70);
                    }
                    em {
                        display: inline-block;
                        vertical-align: middle;
                        background: url(../img/biaoqi_moreicon.png) no-repeat -1px -1px;
                        width: 26px;
                        height: 22px;
                    }
                }
                .show_a {
                    box-shadow: 0 0 3px rgba(0,0,0,.1);
                    background: #fff;
                    margin-bottom: 15px;
                    .show_at {
                        font-size: 24px;
                        color: #444;
                        font-weight: 400;
                        padding: 20px 0 15px;
                        text-align: center;
                    }
                    .show_atips {
                        text-align: center;
                        margin-bottom: 15px;
                    }
                    .show_adesc {
                        border: 1px solid #e8e8e8;
                        border-left: none;
                        border-right: none;
                        padding: 8px 15px;
                        margin: 0;
                        color: #888;
                        font-size: 14px;
                        line-height: 24px;
                        span {
                            color: #ff6f3d;
                        }
                    }
                    .show_ac {
                        padding: 15px 15px 30px;
                        p {
                            color: #555;
                            line-height: 28px;
                            font-size: 14px;
                        }
                        img {
                            max-width: 100%;
                        }
                    }
                    .prev {
                        padding: 0 15px 15px;
                        p {
                            line-height: 26px;
                            font-size: 14px;
                            color: #888;
                            a {
                                font-size: 14px;
                                color: #888;
                                &:hover {
                                    color: #ff6f3d;
                                }
                            }
                        }
                    }
                }
            }
            .nmain_r {
                width: 300px;
                .art_catlist {
                    width: 100%;
                    box-shadow: 0 0 3px rgba(0,0,0,.1);
                    padding: 15px;
                    background: #fff;
                    margin-bottom: 15px;
                    .art_catlist_t {
                        margin-bottom: 15px;
                        border-bottom: 1px solid #f3f3f3;
                        span {
                            display: inline-block;
                            float: left;
                            height: 40px;
                            line-height: 40px;
                            font-size: 16px;
                            font-weight: bold;
                            color: #31424e;
                            border-bottom: 2px solid #ff6f3d;
                        }
                    }
                    .art_catlist_c {
                        ul {
                            li {
                                float: left;
                                height: 35px;
                                border: 1px solid #f0f0f0;
                                margin: 0 8px 10px 0;
                                cursor: pointer;
                                a {
                                    display: inline-block;
                                    width: 100%;
                                    height: 100%;
                                    font-size: 16px;
                                    line-height: 33px;
                                    padding: 0 15px;
                                    color: #ff6f3d;
                                    &:hover {
                                        color: #fff;
                                        background: #ff6f3d;
                                    }
                                }
                            }
                        }
                    }
                }
                .jxnr {
                    width: 100%;
                    box-shadow: 0 0 3px rgba(0,0,0,.1);
                    padding: 15px;
                    background: #fff;
                    margin-bottom: 15px;
                    .jxnr_t {
                        margin-bottom: 15px;
                        border-bottom: 1px solid #f3f3f3;
                        span {
                            display: inline-block;
                            float: left;
                            height: 40px;
                            line-height: 40px;
                            font-size: 16px;
                            font-weight: bold;
                            color: #31424e;
                            border-bottom: 2px solid #ff6f3d;
                        }
                    }
                    .jxnr_c {
                        ul {
                            li {
                                padding: 15px 0;
                                border-bottom: 1px solid #eee;
                                div.fr {
                                    width: 160px;
                                    a {
                                        line-height: 21px;
                                        font-size: 14px;
                                        font-weight: 400;
                                        display: block;
                                        margin-bottom: 10px;
                                        color: #333;
                                        &:hover {
                                            color: #ff6f3d;
                                        }
                                    }
                                    p {
                                        color: #999;
                                    }
                                }
                                div.fl {
                                    width: 95px;
                                    height: 75px;
                                    a {
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                        img {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                            li:last-child {
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1200px) {
    .w1200 {
        width: 100%;
        padding: 0 10px;
    }
    .main {
        padding: 10px 0;
        .w1200 {
            .main_ll {
                width: 25%;
                .header_bl {
                    width: 100%;
                    background-color: #ff5e52;
                    img {
                        width: 100%;
                    }
                }

            }
            .main_l {
                width: 70%;
                float: right;
                .banner {
                    width: 100%;
                    .swiper-slide {
                        width: 100%;
                        img {
                            width: 100%;
                        }
                    }
                }
                .iart_t {
                    margin-top: 15px;
                    padding: 0 15px 0;
                    width: 100%;
                    height: 50px;
                    border-top: none;
                    border-bottom: 1px solid #EEE;
                    background: #fff;
                    h2 {
                       float: left;
                        padding-bottom: 12px;
                        height: 50px;
                        line-height: 50px;
                        border-bottom: 2px solid #FF5E52;
                        color: #444;
                        font-size: 16px;
                        font-weight: normal; 
                    }
                }
                .iart_list {
                    ul {
                        background: #fff;
                        box-shadow: 0 0 3px rgba(0,0,0,.1);
                        padding: 0 15px;
                        li {
                            width: 100%;
                            position: relative;
                            padding: 25px 0;
                            div.fl {
                                position: relative;
                                float: left;
                                width: 43%;
                                height: auto;
                                overflow: hidden;
                                a {
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    position: relative;
                                    &:hover {
                                        img {
                                            transform: scale(1.2);
                                            -moz-transform: scale(1.2);
                                            -webkit-transform: scale(1.2);
                                            -o-transform: scale(1.2);
                                            -ms-transform: scale(1.2);
                                        }
                                    }
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        transition: all .5s;
                                        -moz-transition: all .5s; 
                                        -webkit-transition: all .5s; 
                                        -o-transition: all .5s;
                                    }
                                    span {
                                        display: block;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        text-align: center;
                                        height: 30px;
                                        line-height: 24px;
                                        font-size: 12px;
                                        color: #fff;
                                        padding: 3px 8px;
                                    }
                                }
                            }
                            div.fr {
                                width: 50%;
                                a {
                                    line-height: 40px;
                                    font-size: 18px;
                                    color: #31424e;
                                    font-weight: bold;
                                    &:hover {
                                        color: #ff6f3d;
                                    }
                                }
                                .p1 {
                                    font-size: 14px;
                                    color: #5e6b73;
                                    line-height: 22px;
                                    padding: 10px 0;
                                }
                                .p2 {
                                    height: 28px;
                                    line-height: 28px;
                                    font-size: 13px;
                                    color: #9baab6;
                                }
                            }
                        }
                        li:nth-child(4n) {
                            div.fl {
                                a {
                                    span {
                                        background-color: #29bbc0;
                                    }
                                }
                            }
                        }
                        li:nth-child(4n+1) {
                            div.fl {
                                a {
                                    span {
                                        background-color: #ee5757;
                                    }
                                }
                            }
                        }
                        li:nth-child(4n+2) {
                            div.fl {
                                a {
                                    span {
                                        background-color: #34415d;
                                    }
                                }
                            }
                        }
                        li:nth-child(4n+3) {
                            div.fl {
                                a {
                                    span {
                                        background-color: #b145b8;
                                    }
                                }
                            }
                        }
                    }
                }
                .iart_list2 {
                    width: 100%;
                    background: #fff;
                    padding: 15px;
                    ul {
                        li {
                            float: left;
                            width: 23%;
                            margin-right: 2.6%;
                            .img {
                                display: block;
                                width: 100%;
                                height: auto;
                                img {
                                    width: 100%;
                                    height: auto;
                                }
                            }
                            .t {
                                display: block;
                                font-size: 14px;
                                color: #666;
                                line-height: 24px;
                                &:hover {
                                    color: #ff5e52;
                                }
                            }
                        }
                        li:nth-child(4n) {
                            margin-right: 0;
                        }
                    }
                }
                .load_more_btn {
                    display: block;
                    background: #f07859;
                    width: 220px;
                    height: 40px;
                    line-height: 40px;
                    margin: 20px auto 10px;
                    font-size: 14px;
                    color: #fff;
                    border-radius: 20px;
                    text-align: center;
                    &:hover {
                        opacity:0.7;filter:alpha(opacity=70);
                    }
                    em {
                        display: inline-block;
                        vertical-align: middle;
                        background: url(../img/biaoqi_moreicon.png) no-repeat -1px -1px;
                        width: 26px;
                        height: 22px;
                    }
                }
            }
            .main_r {
                display: none;
            }
        }
    }

    .footer {
        width: 100%;
        background: #333;
        border-top: 14px solid #f07859;
        position: relative;
        z-index: 999;
        .footer_t {
            padding: 40px 0;
            border-bottom: 1px solid #515151;
            .footer_tl {
                width: 100%;
                margin-bottom: 20px;
                .footer_list {
                    width: 33%;
                    text-align: center;
                    margin-right: 0.5%;
                    h5 {
                        font-size: 18px;
                        color: #fff;
                        margin-bottom: 20px;
                        font-weight: normal;
                    }
                    a {
                        display: block;
                        line-height: 30px;
                        font-size: 14px;
                        color: #aeaeae;
                    }
                }  
                .footer_list:nth-child(3n) {
                    margin-right: 0;
                }
            }
            .footer_tm {
                width: 100%;
                text-align: center;
                margin-bottom: 40px;
                .p1 {
                    font-size: 18px;
                    color: #fff;
                    margin-bottom: 20px;
                }
                .p2 {
                    line-height: 30px;
                    font-size: 14px;
                    color: #aeaeae;
                }
            }
            .footer_tr {
                width: 100%;
                img {
                    width: 108px;
                    display: block;
                    margin: 0 auto;
                }
                p {
                    text-align: center;
                    color: #fff;
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
        .footer_b {
            color: #bababa;
            padding: 15px 0;
        }
    }
    .nmain {
        .w1200 {
            .main_ll {
                width: 190px;
                margin-right: 15px;
                background-color: #fff;
                position: fixed;
                .header_bl {
                    width: 100%;
                    background-color: #ff5e52;
                }
                .nav {
                    width: 100%;
                    background-color: #fff;
                    ul {
                        width: 100%;
                        li {
                            width: 100%;
                            a {
                                display: block;
                                width: 100%;
                                height: 44px;
                                line-height: 44px;
                                padding-left: 30px;
                                font-size: 16px;
                                color: #666;
                                &:hover {
                                    color: #444;
                                    background-color: #f6f6f6;
                                }
                            }
                            a.active {
                                color: #ff5e52;
                            }
                        }
                    }
                }
                .header_br {
                    width: 100%;
                    padding: 15px;
                    background-color: #f6f6f6;
                    border: 1px solid #eee;
                    position: relative;
                    input[type="text"] {
                        width: 100%;
                        height: 34px;
                        line-height: 34px;
                        border: 1px solid #ff5e52;
                        text-indent: 5px;
                    }
                    input[type="submit"] {
                        width: 18%;
                        height: 34px;
                        line-height: 34px;
                        position: absolute;
                        right: 15px;
                        top: 15px;
                        border: 1px solid #ff5e52;
                        background: #ff5e52;
                    }
                    i {
                        position: absolute;
                        color: #fff;
                        font-size: 20px;
                        right: 22px;
                        top: 22px;
                    }
                }
            }
            .crumbs {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 14px;
                a {
                    display: inline-block;
                    font-size: 14px;
                    height: 50px;
                }
                a:first-child {
                    display: inline-block;
                    float: left;
                    width: 18px;
                    background: url(../img/chart.png) no-repeat 0 -20px;
                }
            }
            .nmain_l {
                width: 70%;
                float: right;
                .artlist {
                    ul {
                        background: #fff;
                        box-shadow: 0 0 3px rgba(0,0,0,.1);
                        padding: 0 15px;
                        li {
                            width: 100%;
                            position: relative;
                            border-bottom: 1px dotted #e2e2e2;
                            padding: 20px 0;
                            div.fl {
                                position: relative;
                                float: left;
                                width: 43%;
                                height: auto;
                                overflow: hidden;
                                a {
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    position: relative;
                                    &:hover {
                                        img {
                                            transform: scale(1.2);
                                            -moz-transform: scale(1.2);
                                            -webkit-transform: scale(1.2);
                                            -o-transform: scale(1.2);
                                            -ms-transform: scale(1.2);
                                        }
                                    }
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        transition: all .5s;
                                        -moz-transition: all .5s; 
                                        -webkit-transition: all .5s; 
                                        -o-transition: all .5s;
                                    }
                                    span {
                                        display: block;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        text-align: center;
                                        height: 30px;
                                        line-height: 24px;
                                        font-size: 12px;
                                        color: #fff;
                                        padding: 3px 8px;
                                    }
                                }
                            }
                            div.fr {
                                width: 50%;
                                a {
                                    line-height: 40px;
                                    font-size: 18px;
                                    color: #31424e;
                                    font-weight: bold;
                                    &:hover {
                                        color: #ff6f3d;
                                    }
                                }
                                .p1 {
                                    font-size: 14px;
                                    color: #5e6b73;
                                    line-height: 22px;
                                    padding: 10px 0;
                                }
                                .p2 {
                                    height: 28px;
                                    line-height: 28px;
                                    font-size: 13px;
                                    color: #9baab6;
                                }
                            }
                        }
                        li:nth-child(4n) {
                            div.fl {
                                a {
                                    span {
                                        background-color: #29bbc0;
                                    }
                                }
                            }
                        }
                        li:nth-child(4n+1) {
                            div.fl {
                                a {
                                    span {
                                        background-color: #ee5757;
                                    }
                                }
                            }
                        }
                        li:nth-child(4n+2) {
                            div.fl {
                                a {
                                    span {
                                        background-color: #34415d;
                                    }
                                }
                            }
                        }
                        li:nth-child(4n+3) {
                            div.fl {
                                a {
                                    span {
                                        background-color: #b145b8;
                                    }
                                }
                            }
                        }
                    }
                }
                .load_more_btn {
                    display: block;
                    background: #f07859;
                    width: 220px;
                    height: 40px;
                    line-height: 40px;
                    margin: 20px auto;
                    font-size: 14px;
                    color: #fff;
                    border-radius: 20px;
                    text-align: center;
                    &:hover {
                        opacity:0.7;filter:alpha(opacity=70);
                    }
                    em {
                        display: inline-block;
                        vertical-align: middle;
                        background: url(../img/biaoqi_moreicon.png) no-repeat -1px -1px;
                        width: 26px;
                        height: 22px;
                    }
                }
                .show_a {
                    box-shadow: 0 0 3px rgba(0,0,0,.1);
                    background: #fff;
                    margin-bottom: 15px;
                    .show_at {
                        font-size: 24px;
                        color: #444;
                        font-weight: 400;
                        padding: 20px 0 15px;
                        text-align: center;
                    }
                    .show_atips {
                        text-align: center;
                        margin-bottom: 15px;
                    }
                    .show_adesc {
                        border: 1px solid #e8e8e8;
                        border-left: none;
                        border-right: none;
                        padding: 8px 15px;
                        margin: 0;
                        color: #888;
                        font-size: 14px;
                        line-height: 24px;
                        span {
                            color: #ff6f3d;
                        }
                    }
                    .show_ac {
                        padding: 15px 15px 30px;
                        p {
                            color: #555;
                            line-height: 28px;
                            font-size: 14px;
                        }
                        img {
                            max-width: 100%;
                        }
                    }
                    .prev {
                        padding: 0 15px 15px;
                        p {
                            line-height: 26px;
                            font-size: 14px;
                            color: #888;
                            a {
                                font-size: 14px;
                                color: #888;
                                &:hover {
                                    color: #ff6f3d;
                                }
                            }
                        }
                    }
                }
            }
            .nmain_r {
                display: none;
            }
        }
    }
}



@media screen and (max-width: 768px) { 
    .pc {
        display: none;
    }
    .w1200 {
        width: 100%;
        padding: 0;
    }
    .header {
        height: 53px;
    }
    .header_bl {
        width: 100%;
        height: 53px;
        background-color: #d2d2d2;
        text-align: center;
        position: fixed;
        left: 0;
        width: 100%;
        z-index: 999;
        img {
            height: 53px;
        }
        a {
            img {
                height: auto;
                margin-top: 0;
            }
        }
    }
    .main {
        width: 100%;
        padding: 0 0 10px;
        .w1200 {
            .main_ll {
                display: none;
            }
            .main_l {
                width: 100%;
                .banner {
                    .swiper-slide {
                        img {
                            width: 100%;
                        }
                    }
                }
                .iart_t {
                    margin-top: 15px;
                    padding: 0 15px 0;
                    width: 100%;
                    height: 50px;
                    border-top: none;
                    border-bottom: 1px solid #EEE;
                    background: #fff;
                    h2 {
                       float: left;
                        padding-bottom: 12px;
                        height: 50px;
                        line-height: 50px;
                        border-bottom: 2px solid #FF5E52;
                        color: #444;
                        font-size: 16px;
                        font-weight: normal; 
                    }
                }
                .iart_list {
                    ul {
                        background: #fff;
                        box-shadow: 0 0 3px rgba(0,0,0,.1);
                        padding: 0 15px;
                        li {
                            width: 100%;
                            position: relative;
                            padding: 25px 0;
                            div.fl {
                                position: relative;
                                float: left;
                                width: 40%;
                                overflow: hidden;
                                a {
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    position: relative;
                                    &:hover {
                                        img {
                                            transform: scale(1.2);
                                            -moz-transform: scale(1.2);
                                            -webkit-transform: scale(1.2);
                                            -o-transform: scale(1.2);
                                            -ms-transform: scale(1.2);
                                        }
                                    }
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        transition: all .5s;
                                        -moz-transition: all .5s; 
                                        -webkit-transition: all .5s; 
                                        -o-transition: all .5s;
                                    }
                                    span {
                                        display: none;
                                    }
                                }
                            }
                            div.fr {
                                width: 55%;
                                a {
                                    font-size: 14px;
                                    color: #31424e;
                                    font-weight: bold;
                                    &:hover {
                                        color: #ff6f3d;
                                    }
                                }
                                .p1 {
                                    font-size: 14px;
                                    color: #5e6b73;
                                    line-height: 22px;
                                    padding: 10px 0;
                                }
                                .p2 {
                                    display: none;
                                }
                            }
                        }
                        li:nth-child(4n) {
                            div.fl {
                                a {
                                    span {
                                        background-color: #29bbc0;
                                    }
                                }
                            }
                        }
                        li:nth-child(4n+1) {
                            div.fl {
                                a {
                                    span {
                                        background-color: #ee5757;
                                    }
                                }
                            }
                        }
                        li:nth-child(4n+2) {
                            div.fl {
                                a {
                                    span {
                                        background-color: #34415d;
                                    }
                                }
                            }
                        }
                        li:nth-child(4n+3) {
                            div.fl {
                                a {
                                    span {
                                        background-color: #b145b8;
                                    }
                                }
                            }
                        }
                    }
                }
                .iart_list2 {
                    width: 100%;
                    background: #fff;
                    padding: 15px;
                    ul {
                        li {
                            float: left;
                            width: 48%;
                            margin-right: 4%;
                            margin-bottom: .5rem;
                            .img {
                                display: block;
                                width: 100%;
                                height: auto;
                                img {
                                    width: 100%;
                                    height: auto;
                                }
                            }
                            .t {
                                display: block;
                                font-size: 14px;
                                color: #666;
                                line-height: 24px;
                                &:hover {
                                    color: #ff5e52;
                                }
                            }
                        }
                        li:nth-child(2n) {
                            margin-right: 0;
                        }
                    }
                }
                .load_more_btn {
                    display: block;
                    background: #f07859;
                    width: 220px;
                    height: 40px;
                    line-height: 40px;
                    margin: 20px auto 10px;
                    font-size: 14px;
                    color: #fff;
                    border-radius: 20px;
                    text-align: center;
                    &:hover {
                        opacity:0.7;filter:alpha(opacity=70);
                    }
                    em {
                        display: inline-block;
                        vertical-align: middle;
                        background: url(../img/biaoqi_moreicon.png) no-repeat -1px -1px;
                        width: 26px;
                        height: 22px;
                    }
                }
            }
        }
    }
    .footer {
        width: 100%;
        background: #333;
        border-top: 14px solid #f07859;
        .footer_t {
            padding: 40px 0;
            border-bottom: 1px solid #515151;
            .footer_tl {
                width: 100%;
                margin-bottom: 20px;
                .footer_list {
                    width: 33%;
                    text-align: center;
                    margin-right: 0.5%;
                    h5 {
                        font-size: 18px;
                        color: #fff;
                        margin-bottom: 20px;
                        font-weight: normal;
                    }
                    a {
                        display: block;
                        line-height: 30px;
                        font-size: 14px;
                        color: #aeaeae;
                    }
                }  
                .footer_list:nth-child(3n) {
                    margin-right: 0;
                }
            }
            .footer_tm {
                width: 100%;
                text-align: center;
                margin-bottom: 40px;
                .p1 {
                    font-size: 18px;
                    color: #fff;
                    margin-bottom: 20px;
                }
                .p2 {
                    line-height: 30px;
                    font-size: 14px;
                    color: #aeaeae;
                }
            }
            .footer_tr {
                width: 100%;
                img {
                    width: 108px;
                    display: block;
                    margin: 0 auto;
                }
                p {
                    text-align: center;
                    color: #fff;
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
        .footer_b {
            color: #bababa;
            padding: 15px 0;
        }
    }

    .nmain {
        .w1200 {
            .main_ll {
                display: none;
            }
            .crumbs {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 14px;
                a {
                    display: inline-block;
                    font-size: 14px;
                    height: 50px;
                }
                a:first-child {
                    display: inline-block;
                    float: left;
                    width: 18px;
                    background: url(../img/chart.png) no-repeat 0 -20px;
                }
            }
            .nmain_l {
                width: 100%;
                .artlist {
                    ul {
                        background: #fff;
                        box-shadow: 0 0 3px rgba(0,0,0,.1);
                        padding: 0 15px;
                        li {
                            width: 100%;
                            position: relative;
                            border-bottom: 1px dotted #e2e2e2;
                            padding: 20px 0;
                            div.fl {
                                position: relative;
                                float: left;
                                width: 40%;
                                overflow: hidden;
                                a {
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    position: relative;
                                    &:hover {
                                        img {
                                            transform: scale(1.2);
                                            -moz-transform: scale(1.2);
                                            -webkit-transform: scale(1.2);
                                            -o-transform: scale(1.2);
                                            -ms-transform: scale(1.2);
                                        }
                                    }
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        transition: all .5s;
                                        -moz-transition: all .5s; 
                                        -webkit-transition: all .5s; 
                                        -o-transition: all .5s;
                                    }
                                    span {
                                        display: block;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        text-align: center;
                                        height: 30px;
                                        line-height: 24px;
                                        font-size: 12px;
                                        color: #fff;
                                        padding: 3px 8px;
                                    }
                                }
                            }
                            div.fr {
                                width: 55%;
                                a {
                                    font-size: 14px;
                                    color: #31424e;
                                    font-weight: bold;
                                    &:hover {
                                        color: #ff6f3d;
                                    }
                                }
                                .p1 {
                                    font-size: 14px;
                                    color: #5e6b73;
                                    line-height: 22px;
                                    padding: 10px 0;
                                }
                                .p2 {
                                    display: none;
                                }
                            }
                        }
                    }
                }
                .load_more_btn {
                    display: block;
                    background: #f07859;
                    width: 220px;
                    height: 40px;
                    line-height: 40px;
                    margin: 20px auto;
                    font-size: 14px;
                    color: #fff;
                    border-radius: 20px;
                    text-align: center;
                    &:hover {
                        opacity:0.7;filter:alpha(opacity=70);
                    }
                    em {
                        display: inline-block;
                        vertical-align: middle;
                        background: url(../img/biaoqi_moreicon.png) no-repeat -1px -1px;
                        width: 26px;
                        height: 22px;
                    }
                }
                .show_a {
                    box-shadow: 0 0 3px rgba(0,0,0,.1);
                    background: #fff;
                    margin-bottom: 15px;
                    .show_at {
                        font-size: 24px;
                        color: #444;
                        font-weight: 400;
                        padding: 20px 0 15px;
                        text-align: center;
                    }
                    .show_atips {
                        text-align: center;
                        margin-bottom: 15px;
                    }
                    .show_adesc {
                        border: 1px solid #e8e8e8;
                        border-left: none;
                        border-right: none;
                        padding: 8px 15px;
                        margin: 0;
                        color: #888;
                        font-size: 14px;
                        line-height: 24px;
                        span {
                            color: #ff6f3d;
                        }
                    }
                    .show_ac {
                        padding: 15px 15px 30px;
                        p {
                            color: #555;
                            line-height: 28px;
                            font-size: 14px;
                        }
                        img {
                            max-width: 100%;
                        }
                    }
                    .prev {
                        padding: 0 15px 15px;
                        p {
                            line-height: 26px;
                            font-size: 14px;
                            color: #888;
                            a {
                                font-size: 14px;
                                color: #888;
                                &:hover {
                                    color: #ff6f3d;
                                }
                            }
                        }
                    }
                }
            }
            .nmain_r {
                width: 300px;
                .art_catlist {
                    width: 100%;
                    box-shadow: 0 0 3px rgba(0,0,0,.1);
                    padding: 15px;
                    background: #fff;
                    margin-bottom: 15px;
                    .art_catlist_t {
                        margin-bottom: 15px;
                        border-bottom: 1px solid #f3f3f3;
                        span {
                            display: inline-block;
                            float: left;
                            height: 40px;
                            line-height: 40px;
                            font-size: 16px;
                            font-weight: bold;
                            color: #31424e;
                            border-bottom: 2px solid #ff6f3d;
                        }
                    }
                    .art_catlist_c {
                        ul {
                            li {
                                float: left;
                                height: 35px;
                                border: 1px solid #f0f0f0;
                                margin: 0 8px 10px 0;
                                cursor: pointer;
                                a {
                                    display: inline-block;
                                    width: 100%;
                                    height: 100%;
                                    font-size: 16px;
                                    line-height: 33px;
                                    padding: 0 15px;
                                    color: #ff6f3d;
                                    &:hover {
                                        color: #fff;
                                        background: #ff6f3d;
                                    }
                                }
                            }
                        }
                    }
                }
                .jxnr {
                    width: 100%;
                    box-shadow: 0 0 3px rgba(0,0,0,.1);
                    padding: 15px;
                    background: #fff;
                    margin-bottom: 15px;
                    .jxnr_t {
                        margin-bottom: 15px;
                        border-bottom: 1px solid #f3f3f3;
                        span {
                            display: inline-block;
                            float: left;
                            height: 40px;
                            line-height: 40px;
                            font-size: 16px;
                            font-weight: bold;
                            color: #31424e;
                            border-bottom: 2px solid #ff6f3d;
                        }
                    }
                    .jxnr_c {
                        ul {
                            li {
                                padding: 15px 0;
                                border-bottom: 1px solid #eee;
                                div.fr {
                                    width: 160px;
                                    a {
                                        line-height: 21px;
                                        font-size: 14px;
                                        font-weight: 400;
                                        display: block;
                                        margin-bottom: 10px;
                                        color: #333;
                                        &:hover {
                                            color: #ff6f3d;
                                        }
                                    }
                                    p {
                                        color: #999;
                                    }
                                }
                                div.fl {
                                    width: 95px;
                                    height: 75px;
                                    a {
                                        display: block;
                                        width: 100%;
                                        height: 100%;
                                        img {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                            li:last-child {
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

/****************************** media ******************************/

@import "./components/media";
