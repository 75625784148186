* {
    box-sizing: border-box;
}

*:before,
*:after {
    box-sizing: border-box;
}

blockquote,
body,
button,
dd,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
input,
legend,
li,
ol,
p,
pre,
td,
textarea,
th,
ul {
    margin: 0;
    padding: 0
}

address,
cite,
dfn,
em,
var {
    font-style: normal
}

sup {
    vertical-align: text-top
}

sub {
    vertical-align: text-bottom
}

legend {
    color: #000
}

fieldset,
img {
    border: 0
}

// body,
// button,
// input,
// select,
// textarea {
//     font: 14px/1.5 "\5FAE\8F6F\96C5\9ED1", "arial";
//     color: #333;
//     font-size: 14px;
// }

table {
    border-collapse: collapse;
    border-spacing: 0;
}

ul,
ol {
    list-style: none;
}

ul {
    @extend .clearfix;
}

a,
button,
input[type="submit"],
input[type="button"],
input[type="reset"],
input[type="file"] {
    cursor: pointer;
}

a {
    color: inherit;
    color: expression(this.parentNode.currentStyle.color);
    text-decoration: none;
    &:focus {
        outline: thin dotted;
    }
    &:active,
    &:hover {
        outline: 0;
    }
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 32px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 24px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 16px;
}

small {
    font-size: 12px;
}
